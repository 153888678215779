@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column; /* Align children vertically */
  align-items: center; /* Center children horizontally */
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.connect-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 30px;
  background-image: linear-gradient(rgb(216,239,86), rgb(68,177,122));
  color: #000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  transition: all .2s;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
}

.connect-button:hover {
  background-color: #005f7b;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.navbar {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  height: 100px; /* Adjust height as needed */
  margin-top: 10px;
  margin-bottom: 50px;
}

.logo {
  margin-top: 50px;
  width: 250px; /* Adjust width as needed */
  /* height: auto; Maintain aspect ratio */
}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-bottom: 0px; */
  margin-top: 10px;
  gap: 20px;
}

.button-separator {
  margin-top: 10px;
}

.new-payment-button {
  margin-top: 35px;
  background-color: transparent;
  border: 2px solid white; /* Adjust as needed */
  border-radius: 10px;
  padding: 15px 40px;
  color: white;
  margin-bottom: 10px;
}

.new-payment-button:hover, .send-payments-button:hover {
  cursor: pointer;
  transform: translate(0, -2px);
}

.send-payments-button {
  background-color: transparent;
  background-image: linear-gradient(to right, #4FB678, #F9FE4E);
  /* border: 2px solid white; Adjust as needed */
  border: none;
  border-radius: 10px;
  padding: 15px 40px;
  color: white;
}

.new-payment-button, .send-payments-button {
  width: 250px;
}

.total-amount {
  font-size: 20px;
  margin-top: 20px;
  color: white;
}

.blue-text {
  color: white;
  font-weight: bold
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-message {
  color: #ff7f7f;
  font-size: 0.8em;
  margin-top: 2px;
  padding-left: 10px;
}

.payment-input, .amount-input {
  background-color: rgba(217, 217, 217, 0.15);
  backdrop-filter: blur(10px);
  
  padding: 10px;
  border: 0;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  /* margin-right: 20px; */
  font-size: 16px;
  color: #FFFFFF;

  /* transition: border-color 0.3s ease; */
  /* width: 100%; */
}

.payment-input:focus, .amount-input:focus {
  border: 1px solid #F9FE4E;
  outline: none;
}

.payment-input {
  min-width: 550px;
  padding-left: 40px;
}

.amount-input {
  max-width: 150px;
  text-align: center;
}

/* .form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
} */

/* .form__field {
  font-family: inherit;
  font-weight:700; 
  border: 0;
  outline: 0;
  font-size: 1.3rem;
  padding: 7px 0;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
} */

/* .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  margin-left: 3px;
} */

/* .form__field:focus {
  ~ .form__label {
    top: -10px;
    color: white;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    font-weight:700;    
  }
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image-slice: 1;
} */

/* .form__field:not(:placeholder-shown) ~ .form__label {
  top: -10px;
  color: white;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  font-weight:700;    
} */

.error {
  /* border: 1px solid #ff5f5f; */
  /* background-color: #ffdcdc; */
}

.input-container {
  display: flex;
  flex-direction: column;
  height: 70px;
}

.remove-button {
  /* background-color: transparent; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('/public/remove.png'); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: contain; */
  border: none;
  color: white;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  height: 39px;
  /* font-weight: 100; */
  /* font-size: 16px; */
  /* padding: 0; */
}
.remove-button:hover {
  transform: translate(0, -2px);
}

.remove-icon {
  margin-right: 5px; /* Adjust as needed */
}

.metamask-error-message {
  background: #fffc;
  bottom: 0;
  color: red;
  padding: 10px;
  position: fixed;
  text-align: center;
  width: 100%;
}

.payment-input, .amount-input, .total-amount, .connect-button {
  font-family: 'Inter', sans-serif;
}

.total-amount {
  font-size: 15px;
}

.new-payment-button, .send-payments-button{
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}


@media (max-width: 768px) {
  .connect-button {
    font-size: 11px;
    padding: 8px 11px 8px 11px;
    width: auto;
  }

  .payment-input {
    min-width: auto;
    width: 100%;
  }
  
  .payment-container {
    /* max-width: 100px; */
    /* width: 10%; */
  }

  .center-container {
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  .payment-input {
    min-width: 100px;
  }

  .amount-input {
    min-width: 100px;
  }
}